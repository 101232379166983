import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import NoDataFound from "../common/NoDataFound";
import Collected from "../components/collection/Collections";
import Feedback from "../components/feedback/Feedback";
import Faqs from "../components/support/Faq/Faq";
import AboutUs from "../components/support/aboutUs/AboutUs";
import Blog from "../components/support/blogs/Blog";
import Privacy from "../components/support/privacy/Privacy";
import Terms from "../components/support/termsCondition/Terms";
import SignIn from "../views/auth/SignIn";
import SignUp from "../views/auth/SignUp";
import SignUpOrg from "../views/auth/SignUpOrg";
import Verify from "../views/auth/Verify";
import { getCurrentUserAPI } from "../redux/user/getCurrentUser";
import CreateNft from "../views/clbs/CreateCLB";
import SellNFT from "../views/clbs/SellNFT";
import SingleCLB from "../views/clbs/SingleCLB";
import Collections from "../views/collection/Collections";
import CreateCollection from "../views/collection/CreateCollection";
import SingleCollection from "../views/collection/SingleCollection";
import ExploreCLBs from "../views/explore/Explore";
import Gallery from "../views/gallery/Gallery";
import Home from "../views/home";
import Orders from "../views/orders/Order";
import Profile from "../views/profile";
import EditProfile from "../views/profile/EditProfile";
// import Ticket from "../views/tickets/createTicket";
import ForgetPassword from "../views/auth/ForgetPassword";
import NewPassword from "../views/auth/NewPassword";
import RaiseTicket from "../views/tickets/RaiseTicket";
import CoingateSuccess from "../dialog/CoingateSuccess";
import CoingateFail from "../dialog/CoingateFail";
import CreateTicket from "../views/tickets/CreateTicket";
import { placeMultipleOrdersAPI } from "../redux/orders/placeMultipleOrders";
import LoaderUI from "../ui/LoaderUI";
import { clearCartAPI } from "../redux/category/clearCart";
import ProtectedRoute from "./ProtectedRoute";
import { useLocation } from "react-router-dom";

export default function MainRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const multipleStore = useSelector((state) => state?.placeMultipleOrdersAPI);
  const { result, loading } = multipleStore || {};

  // const accessToken = localStorage.getItem("accessToken");
  // useEffect(() => {
  //   debugger
  //   window.location.reload('/')
  //   dispatch(getCurrentUserAPI());
  // }, []);
  // useEffect(()=>{
  //   window.location.reload('/')
  // },[])

  useEffect(() => {
    // debugger

    if (location.pathname === "/signIn") return;

    const interval = setInterval(() => {
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        dispatch(getCurrentUserAPI());
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [dispatch, location.pathname]);

  useEffect(() => {
    const cart_orderId = localStorage.getItem("Coingate-cart-orderId");
    const cart_orders = JSON.parse(localStorage.getItem("cart-orders"));

    if (cart_orders?.length > 0 && cart_orderId) {
      (async () => {
        dispatch(placeMultipleOrdersAPI({ orders: cart_orders }));
        dispatch(clearCartAPI());
      })();
    }
    // Cleanup when the component unmounts
    return () => {
      localStorage.removeItem("Coingate-cart-orderId");
      localStorage.removeItem("cart-orders");
    };
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <LoaderUI />
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route exact path="/signUp" element={<SignUp />} />
          <Route exact path="/signIn" element={<SignIn />} />
          <Route exact path="/orgSignUp" element={<SignUpOrg />} />
          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/new-password/:token" element={<NewPassword />} />
          {/* <Route exact path="/verify-otp" element={<VerifyOTP />} /> */}
          <Route exact path="/verify/:id" element={<Verify />} />
          {/* <Route exact path="/create" element={<CreateNft />} /> */}
          <Route
            exact
            path="/create"
            element={
              <ProtectedRoute>
                <CreateNft />
              </ProtectedRoute>
            }
          />

          <Route exact path="/sell/:CLBId" element={<SellNFT />} />
          <Route exact path="/profile/edit" element={<EditProfile />} />
          <Route
            exact
            path="/collection/create"
            element={<CreateCollection />}
          />
          <Route exact path="/collection" element={<Collections />} />
          {/* <Route exact path="/explore" element={<ExploreCLBs />} /> */}
          {/* <Route exact path="/explore/:id" element={<SingleCLB />} /> */}
          <Route
            exact
            path="/explore"
            element={
              <ProtectedRoute>
                <ExploreCLBs />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/explore/:id"
            element={
              <ProtectedRoute>
                <SingleCLB />
              </ProtectedRoute>
            }
          />
          <Route exact path="/collection/:id" element={<SingleCollection />} />
          {/* <Route exact path="/orders" element={<Orders />} /> */}
          <Route
            exact
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          {/* <Route exact path="/ticket/create" element={<RaiseTicket />} /> */}
          <Route exact path="/ticket/new" element={<CreateTicket />} />
          <Route exact path="/profile/:id" element={<Profile />} />
          <Route exact path="/aboutUs" element={<AboutUs />} />
          {/* <Route exact path="/aboutUs/whyUs" element={<AboutUs />} /> */}
          <Route exact path="/aboutUs/ourWork" element={<AboutUs />} />
          <Route exact path="/aboutUs/services" element={<AboutUs />} />
          <Route exact path="/aboutUs/getInTouch" element={<AboutUs />} />
          <Route exact path="/blogs" element={<Blog />} />
          <Route exact path="/termsCondtion" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/faqs" element={<Faqs />} />
          <Route exact path="/collections" element={<Collected />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/feedback" element={<Feedback />} />
          <Route exact path="/success/:id" element={<CoingateSuccess />} />
          <Route exact path="/fail" element={<CoingateFail />} />
          <Route exact path="/user/verify/:token" element={<Verify />} />
          <Route exact path="*" element={<NoDataFound />} />
        </Routes>
      )}
    </>
  );
}
