import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, IconButton, Modal, Stack, Typography } from "@mui/material";
import { PeraWalletConnect } from "@perawallet/connect";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import coingate from "../assets/png/coingate.png";
import paraLogo from "../assets/png/paraLogo.png";
import RazorpayLogo from "../assets/svg/RazorpayLogo.svg";
import { placeOrderAPI } from "../redux/orders/placeOrder";
import rzPayHandler from "../service/RazorpayIntegration";
import HttpService from "../../src/config/authAxios";
import toast from "react-hot-toast";
import { useSDK } from "@metamask/sdk-react";
import { placeMultipleOrdersAPI } from "../redux/orders/placeMultipleOrders";
import { useLocation } from "../LocationContext";
import axios from 'axios';


export default function BuyCLBConfimation({
  open,
  setOpen,
  CLB,
  CLBs,
  setMintConfirm,
  onClearCart,
  bid = false,
  price = {},
}) {
  const buyCLBSelector = useSelector((state) => state?.buyCLB);
  const { message, error } = buyCLBSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const [account, setAccount] = useState("");
   const { convertToCurrency } = useLocation();

  // const connect = async () => {
  //   let address;

  //   try {
  //     const accounts = await sdk?.connect();
  //     console.log(accounts);
  //     address = accounts?.[0];
  //     setAccount(accounts?.[0]);
  //   } catch (err) {
  //     console.warn(`failed to connect..`, err);
  //   }
  //   return address;
  // };

  const connect = async () => {

    try {
      const accounts = await sdk?.connect();
      // console.log(accounts);
      setAccount(accounts?.[0]);
      try {
        const { data } = await HttpService.put("/users/update-metamask", {
          metamaskAddress: accounts?.[0],
        });
        toast.success("Wallet Connection Done")
        // console.log(data);
      } catch (err) {
        console.warn(`failed to connect..`, err);
        toast.error("Wallet Connection Failed", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.warn(`failed to connect..`, err);
      toast.error("Wallet Connection Failed", {
        duration: 5000,
        position: "bottom-left",
      });
    }
  };

  // useEffect(()=>{
  //  connect()
  // }, [])

  console.log(price, bid);

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: user } = getCurrentUserSelector;

  const handleClose = () => {
    localStorage.removeItem("Coingate-orderId");
    setOpen(false);
  };


  const succesfullPayments = async (response = {}) => {
    console.log("hittedsuccesfull")
    if (CLBs && CLBs.length > 0) {
      const orders = CLBs?.map((item) => ({
        sellerId: item?.clb?.ownerId,
        clbId: item?.clb?._id,
        transId: response.razorpay_payment_id,
        price: {
          inr: bid ? price?.inr : item?.clb?.cost?.inr,
          usd: bid ? price?.usd : item?.clb?.cost?.usd,
        },
        status: "Successfull",
      }));
     debugger
      await dispatch(placeMultipleOrdersAPI({ orders }));
      await onClearCart();
      window.location.reload();
    } else {

      dispatch(
        placeOrderAPI({
          sellerId: CLB?.ownerId,
          clbId: CLB?._id,
          transId:
            response.razorpay_payment_id ??
            localStorage.getItem("Coingate-orderId"),
          price: {
            inr: bid ? price?.inr : CLB?.cost?.inr,
            usd: bid ? price?.usd : CLB?.cost?.usd,
          },
          status: "Successfull",
        })
      );
    }

    if (!error) {
      setOpen(false);
      // debugger
      const address = await connect();
      console.log("address", address);
      // if (!CLB?.mint) {
      //   setMintConfirm(true);
      // } else if (CLB?.mint && address) {
      //   try {
      //     const { data } = await HttpService.post("/nft/transfer", {
      //       CLBId: CLB?._id,
      //       walletAddress: address,
      //     });
      //     console.log("data", data);

      //     toast.success("NFT successfully transferred to your wallet", {
      //       duration: 5000,
      //       position: "bottom-left",
      //     });
      //   } catch (error) {
      //     console.log("error", error);
      //     toast.error("Payment Successful, but NFT transfer failed", {
      //       duration: 5000,
      //       position: "bottom-left",
      //     });
      //   }
      // }
    }

    if (user?.country === "India") {
      if (!user?.kycVerified) {
        toast.success(
          "Payment Successful, Please complete your KYC ",
          {
            duration: 5000,
            position: "bottom-left",
          }
        );
      } else {
        toast.success("Payment Successful", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } else {
      if (!user?.metamaskConnected) {
        toast.success(
          "Payment Successful, Please connect your metamask wallet",
          {
            duration: 5000,
            position: "bottom-left",
          }
        );
      } else {
        toast.success("Payment Successful", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    }

    return;
  };
  console.log(CLB);

  const handleRzPay = () => {
    console.log("razorpay logs", CLB);
    let price1;
    if (CLB) {
      price1 = !bid ? CLB?.cost?.inr : price.inr;
    } else {
      price1 = CLBs.reduce((acc, item) => {
        return (acc += item?.clb?.cost?.inr);
      }, 0);
    }
    console.log(price1);
    rzPayHandler(price1, succesfullPayments);
  };

  const handleParaPay = () => {
    const peraWallet = new PeraWalletConnect({ chainId: "416001" });
    peraWallet.connect();
  };

  // console.log("CURRENT HOSTNAME: " + window.location.href);
 const [exchangeRate, setExchangeRate] = useState(1); 
  // const fetchExchangeRate = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await axios.get(
  //       `https://openexchangerates.org/api/latest.json?app_id=3118004813b1465ebb7f3b57e1f160b6&base=USD`
  //     );
  //     const rate = res?.data?.rates?.INR || 1;
  //     setExchangeRate(rate);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching exchange rate:", error);
  //     setLoading(false);
  //   }
  // };
  const handleCoingate = async () => {

    // let totalAmount = 0;
    // if(CLB !== undefined){
    //   totalAmount =  convertToCurrency(CLB?.cost?.inr)
     
    //   console.log("totalss", totalAmount)
    // } else {
    //   totalAmount =  convertToCurrency(CLB?.cost?.inr)
    //   console.log("total", totalAmount)
    // }
    // console.log("totalAmount", totalAmount)
    let totalAmount = 0;

  
    // Fetch latest exchange rate inside handleCoingate
    const res = await axios.get(
      `https://openexchangerates.org/api/latest.json?app_id=3118004813b1465ebb7f3b57e1f160b6&base=USD`
    );
    const exchangeRate = res?.data?.rates?.INR || 1; // Default to 1 if API fails

    console.log("Latest Exchange Rate (INR to USD):", exchangeRate);

    // Convert INR to USD
    if (CLB !== undefined) {
      totalAmount = (CLB?.cost?.inr / exchangeRate).toFixed(2);
    } else {
      totalAmount = (CLB?.cost?.inr / exchangeRate).toFixed(2);
    }

    console.log("Converted Total Amount (USD):", totalAmount);

  
    const paymentObject = {
      price_amount: totalAmount,
      price_currency: "usd",
      receive_currency: "usd",
      success_url: window.location.href,
      cancel_url: window.location.href,
    };

    

    try {
      const response = await HttpService.post(
        "/payments/create-payment",
        paymentObject
      );
      if (response.data && response.data.payment_url) {
        if (CLBs && CLBs.length > 0){
          localStorage.setItem("Coingate-cart-orderId", response.data.id);
          const orders = CLBs?.map((item) => ({
            // sellerId: item?.clb?.creator?._id,
            sellerId: item?.clb?.ownerId,
            clbId: item?.clb?._id,
            transId: response.data.id,
            price: {
              inr: bid ? price?.inr : item?.clb?.cost?.inr,
              usd: bid ? price?.usd : item?.clb?.cost?.usd,
            },
            status: "Successfull",
          }));
          localStorage.setItem("cart-orders", JSON.stringify(orders));
        }else{
          localStorage.setItem("Coingate-orderId", response.data.id);
        }
        toast.success("Redirecting to Payment page", {
          duration: 5000,
          position: "bottom-left",
        });

        debugger
        window.location.href = response.data.payment_url;
        // succesfullPayments();
        // connect()
        // navigate(`/explore/${clbId}`);

      } else {
        toast.error("Payment creation failed.", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        duration: 5000,
        position: "bottom-left",
      });
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal">
        <Card className="alertBox">
          <Stack direction="row" sx={{ my: 2 }}>
            <IconButton style={{color: "transparent", userSelect: "none", pointerEvents: "none"}}>
              <CloseIcon />
            </IconButton>
            <Typography align="center" variant="h5" sx={{ flex: 1 }}>
              Buy CLB !
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography>
                Are you sure you want to buy.{CLB?.title} You will be getting
                confirmation notification!
              </Typography>
              
            </Stack>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
              {/* <Box
                sx={{ bgcolor: "#fff", width: 150, p: 2 }}
                onClick={handleRzPay}
              >
                <img width={"100%"} src={RazorpayLogo} alt="razorpay" />
              </Box> */}
              {/* <Box
                sx={{ bgcolor: "#fff", width: 150, p: 2 }}
                onClick={handleParaPay}
              >
                <img width={"100%"} src={paraLogo} alt="para wallet" />
              </Box> */}
              <Box
                sx={{ bgcolor: "black", width: 150, p: 2 }}
                onClick={handleCoingate}
              >
                <img width={"100%"} src={coingate} alt="para wallet" />
              </Box>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}
