import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../common/NoDataFound";
import Header from "../../components/header/Header";
import { dateYYMMDD } from "../../helper/date";
import { getAllOrdersAPI } from "../../redux/orders/getAllOrders";
import Style from "../../styles/order.module.scss";
import AddKyc from "./AddKyc";
import { useSDK } from "@metamask/sdk-react";
import HttpService from "../../config/authAxios";
import { useNavigate } from "react-router-dom";
import IndianConfirmModal from "../../dialog/IndianConfirmModal";
import BuyCLBConfimation from "../../dialog/BuyCLBConfimation";
import MintingConfirmModal from "../../dialog/MintingConfirmModal";
import Resell from "../../dialog/Resell";
import { getCurrentUserOrgAPI } from "../../redux/user/getCurrentUserOrg";
import AssignClbModal from "../clbs/AssignClbModal";
import { createOkycAPI } from "../../redux/kyc/createOkyc";
import { revokeClbAPI } from "../../redux/clb/revokeClb";
import toast from "react-hot-toast";
import { useLocation } from "../../LocationContext";

const Order = () => {
  const getAllOrdersSelector = useSelector((state) => state?.getAllOrders);
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const getCurrentUserOrgSelector = useSelector(
    (state) => state?.getCurrentUserOrg
  );
  const { result: user } = getCurrentUserSelector;
  const { result: org } = getCurrentUserOrgSelector;
  const { result, loading } = getAllOrdersSelector;
  const [confirmModal, setConfirmModal] = useState(false);
  const [buyCLBComf, setBuyCLBComf] = useState(false);
  const [mintConfirm, setMintConfirm] = useState(false);
  const [openKYC, setOpenKYC] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openResell, setOpenResell] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
   const { convertToCurrency } = useLocation();

  useEffect(() => {
    const getOrg = async () => {
      await dispatch(getCurrentUserOrgAPI());
    };

    getOrg();

    const rzPayElem = document.createElement("script");
    rzPayElem.src = "https://checkout.razorpay.com/v1/checkout.js";
    rzPayElem.async = true;
    document.body.appendChild(rzPayElem);
  }, []);

  const revokeClbSelector = useSelector((state) => state?.revokeClbReducer);
  const { loading: revokeLoading } = getCurrentUserOrgSelector;

  const [account, setAccount] = useState("");
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  // const connect = async () => {
  //   try {
  //     const accounts = await sdk?.connect();
  //     console.log(accounts);
  //     setAccount(accounts?.[0]);
  //   } catch (err) {
  //     console.warn(`failed to connect..`, err);
  //   }
  // };

  const connect = async () => {

    try {
      const accounts = await sdk?.connect();
      // console.log(accounts);
      setAccount(accounts?.[0]);
      try {
        const { data } = await HttpService.put("/users/update-metamask", {
          metamaskAddress: accounts?.[0],
        });
        // console.log(data);
      } catch (err) {
        console.warn(`failed to connect..`, err);
        toast.error("Wallet Connection Failed", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.warn(`failed to connect..`, err);
      toast.error("Wallet Connection Failed", {
        duration: 5000,
        position: "bottom-left",
      });
    }
  };

  const [addKycState, setAddKycState] = useState(false);
  const [CLBFilter, setCLBFilter] = useState("All");

  const [columns, setColumns] = useState([]);
  const [isAssigningClb, setIsAssigningClb] = useState(false);
  const [clbId, setClbId] = useState();

  const handleFilterCollectCLBs = (filter) => {
    console.log(filter);
    setCLBFilter(filter);
  };

  const handleAssignClb = (id, ex) => {
    console.log("haha", ex);
    setClbId(id);
    setIsAssigningClb(true);
  };

  const handleRevokeClb = async (id) => {
    await dispatch(revokeClbAPI({ clbId: id }));
    await dispatch(getAllOrdersAPI(CLBFilter));
  };

  useEffect(() => {
    dispatch(getAllOrdersAPI(CLBFilter));

    if (CLBFilter === "All") {
      setColumns([
        {
          field: "clbId",
          headerName: "NFT Asset Details",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Box sx={{ p: 2 }}>
                <Box className={Style?.clbBox}>
                  {params?.row?.clbId?.type == "2d" && (
                    <img src={params?.row?.clbId?.files[0]} style={{width: "100%", height: "100%"}}/>
                  )}
                  {params?.row?.clbId?.type == "3d" && (
                    <model-viewer
                      style={{ width: "100%", height: "100%" }}
                      src={params?.row?.clbId?.files[0]}
                      ar-modes="webxr scene-viewer quick-look"
                      camera-controls
                      poster="poster.webp"
                      shadow-intensity="1"
                      disable
                    ></model-viewer>
                  )}
                </Box>
                <Typography
                  mt={1}
                  variant="subtitle2"
                  sx={{ width: "100%", textAlign: "center" }}
                >
                  {params?.row?.clbId?.title}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "transId",
          headerName: "Order ID/ Transaction ID",
          width: 250,
          align: "left",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "price.inr",
          headerName: "Price(INR)",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          valueGetter: ({ row }) => row.price.inr,
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2">
                {params?.row?.price?.inr}
              </Typography>
            );
          },
        },
        {
          field: "price.usd",
          headerName: "Price(USD)",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          valueGetter: ({ row }) => row.price.inr,
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2">
                {/* {params?.row?.price?.usd} */}
               {convertToCurrency(params?.row?.price?.inr)}

              </Typography>
            );
          },
        },
        {
          field: "created_at",
          headerName: "Dates",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Typography>{dateYYMMDD(params?.row?.created_at)}</Typography>
            );
          },
        },
        {
          field: "Order Status",
          headerName: "Order Status",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Stack sx={{ py: 1 }}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Payment</Typography>
                  {params?.row?.status == "InProgress" && (
                    <Box sx={{ bgcolor: "#35ABAF", p: 0.5, color: "#fff" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                  {params?.row?.status == "Successfull" && (
                    <Box sx={{ bgcolor: "#35ABAF", p: 0.5, color: "#fff" }}>
                      {/* {params?.row?.status} */}
                      Successful
                    </Box>
                  )}
                  {params?.row?.status == "Pending" && (
                    <Box sx={{ bgcolor: "#FFFF49", p: 0.5, color: "#000" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                  {params?.row?.status == "Rejected" && (
                    <Box sx={{ bgcolor: "red", p: 0.5, color: "#fff" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                </Box>
              </Stack>
            );
          },
        },
        // {
        //   field: "Mint Clb",
        //   headerName: "Mint Clb",
        //   width: 300,
        //   align: "left",
        //   headerClassName: "super-app-theme--header",
        //   renderCell: (params) => {
        //     console.log(params);
        //     if (user?.type === "Organization" && org?.status !== "Approved") {
        //       return <Typography>Not Allowed</Typography>;
        //     } else
        //       return (
        //         <>
        //           {!params?.row?.clbId?.mint &&
        //           params?.row?.transId &&
        //           params?.row?.transId !== "" ? (
        //             <Stack sx={{ py: 1 }}>
        //               <Box sx={{ width: "100%" }}>
        //                 <Button
        //                   className="gradBtn"
        //                   onClick={() => {
        //                     setMintConfirm(true);
        //                     setSelectedOrder(params?.row);
        //                   }}
        //                 >
        //                   Mint Now
        //                 </Button>
        //               </Box>
        //             </Stack>
        //           ) : (
        //             <>
        //               {params?.row?.clbId?.mint ? (
        //                 <p className="text-green-300">Minted</p>
        //               ) : (
        //                 <p className="text-red-300">Not minted</p>
        //               )}
        //             </>
        //           )}
        //         </>
        //       );
        //   },
        // },
        // {
        //   field: "assignClb",
        //   headerName: "Assign CLB",
        //   width: 300,
        //   align: "left",
        //   headerClassName: "super-app-theme--header",
        //   renderCell: (params) => {
        //     return params.row.creator?.role === "Admin" ||
        //       params.row.creator?.role === "SuperAdmin" ? (
        //       <Stack sx={{ py: 1 }}>
        //         <Box sx={{ width: "100%" }}>
        //           {params?.row?.clbId?.assignedTo?.name ? (
        //             <Button
        //               className="gradBtn2"
        //               onClick={() => {
        //                 handleRevokeClb(params?.row?.clbId?._id);
        //               }}
        //               disabled={revokeLoading}
        //             >
        //               Revoke
        //             </Button>
        //           ) : (
        //             <Button
        //               className="gradBtn2"
        //               onClick={() => {
        //                 handleAssignClb(
        //                   params?.row?.clbId?._id,
        //                   params?.row?.clbId?.assignedTo?.name
        //                 );
        //               }}
        //             >
        //               Assign
        //             </Button>
        //           )}
        //         </Box>
        //       </Stack>
        //     ) : (
        //       <Typography>Not Allowed</Typography>
        //     );
        //   },
        // },
        // {
        //   field: "Action",
        //   headerName: "Action",
        //   width: 300,
        //   align: "left",
        //   headerClassName: "super-app-theme--header",
        //   renderCell: (params) => {
        //     console.log(params);
        //     return (
        //       <Stack sx={{ py: 1 }}>
        //         <Box sx={{ width: "100%" }}>
        //           <Button
        //             className="gradBtn2"
        //             style={{
        //               marginRight: "10px",
        //             }}
        //             onClick={() => {
        //               navigate(`/sell/${params?.row?.clbId?._id}`);
        //             }}
        //           >
        //             Resell
        //           </Button>
        //           <Button
        //             className="gradBtn2"
        //             onClick={() => {
        //               navigate(`/explore/${params?.row?.clbId?._id}`);
        //             }}
        //           >
        //             View
        //           </Button>
        //         </Box>
        //       </Stack>
        //     );
        //   },
        // },
        {
          field: "Action",
          headerName: "Action",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            const isOwner = params?.row?.buyerId?._id === params?.row?.clbId?.ownerId;
            return (
              <Stack sx={{ py: 1 }}>
                <Box sx={{ width: "100%" }}>
                  {isOwner && (
                    <Button
                      className="gradBtn2"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        navigate(`/sell/${params?.row?.clbId?._id}`);
                      }}
                    >
                      Resell
                    </Button>
                  )}
                  <Button
                    className="gradBtn2"
                    onClick={() => {
                      navigate(`/explore/${params?.row?.clbId?._id}`);
                    }}
                  >
                    View
                  </Button>
                </Box>
              </Stack>
            );
          },
        },
     
      ]);
    } else if (CLBFilter === "Bought") {
      setColumns([
        {
          field: "clbId",
          headerName: "NFT Asset Details",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Box sx={{ p: 2 }}>
                <Box className={Style?.clbBox}>
                  {params?.row?.type == "2d" && (
                    <img src={params?.row?.files?.[0]} width={"100%"} />
                  )}
                  {params?.row?.type == "3d" && (
                    <model-viewer
                      style={{ width: "100%", height: 100 }}
                      src={params?.row?.files?.[0]}
                      ar-modes="webxr scene-viewer quick-look"
                      camera-controls
                      poster="poster.webp"
                      shadow-intensity="1"
                      disable
                    ></model-viewer>
                  )}
                </Box>
                <Typography variant="subtitle2">
                  {params?.row?.clbId?.title}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "transId",
          headerName: "Order ID/ Transaction ID",
          width: 250,
          align: "left",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "price.inr",
          headerName: "Price(INR)",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          valueGetter: ({ row }) => row.price.inr,
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2">
                {params?.row?.price?.inr}
              </Typography>
            );
          },
        },
        {
          field: "price.usd",
          headerName: "Price(USD)",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          valueGetter: ({ row }) => row.price.inr,
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2">
                {/* {params?.row?.price?.usd} */}
                {convertToCurrency(params?.row?.price?.inr)}
                
              </Typography>
            );
          },
        },
        {
          field: "created_at",
          headerName: "Dates",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Typography>{dateYYMMDD(params?.row?.created_at)}</Typography>
            );
          },
        },
        {
          field: "Order Status",
          headerName: "Order Status",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Stack sx={{ py: 1 }}>
                <Box sx={{ width: "100%" }}>
                  <Typography>Payment</Typography>
                  {params?.row?.status == "InProgress" && (
                    <Box sx={{ bgcolor: "#35ABAF", p: 0.5, color: "#fff" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                  {params?.row?.status == "Successfull" && (
                    <Box sx={{ bgcolor: "#35ABAF", p: 0.5, color: "#fff" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                  {params?.row?.status == "Pending" && (
                    <Box sx={{ bgcolor: "#FFFF49", p: 0.5, color: "#000" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                  {params?.row?.status == "Rejected" && (
                    <Box sx={{ bgcolor: "red", p: 0.5, color: "#fff" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                </Box>
              </Stack>
            );
          },
        },
        // {
        //   field: "Mint Clb",
        //   headerName: "Mint Clb",
        //   width: 300,
        //   align: "left",
        //   headerClassName: "super-app-theme--header",
        //   renderCell: (params) => {
        //     if (user?.type === "Organization" && org?.status !== "Approved") {
        //       return <Typography>Not Allowed</Typography>;
        //     } else
        //       return (
        //         <>
        //           {!params?.row?.clbId?.mint ? (
        //             <Stack sx={{ py: 1 }}>
        //               <Box sx={{ width: "100%" }}>
        //                 <Button
        //                   className="gradBtn"
        //                   onClick={() => {
        //                     setMintConfirm(true);
        //                     setSelectedOrder(params?.row);
        //                   }}
        //                 >
        //                   Mint Now
        //                 </Button>
        //               </Box>
        //             </Stack>
        //           ) : (
        //             <>
        //               <p className="text-green-300">Minted</p>
        //             </>
        //           )}
        //         </>
        //       );
        //   },
        // },
        // {
        //   field: "assignClb",
        //   headerName: "Assign CLB",
        //   width: 300,
        //   align: "left",
        //   headerClassName: "super-app-theme--header",
        //   renderCell: (params) => {
        //     return (
        //       <Stack sx={{ py: 1 }}>
        //         <Box sx={{ width: "100%" }}>
        //           {params?.row?.clbId?.assignedTo?.name ? (
        //             <Typography variant="body2" color={"primary"}>
        //               Assigned
        //             </Typography>
        //           ) : (
        //             <Button
        //               className="gradBtn2"
        //               onClick={() => {
        //                 handleAssignClb(
        //                   params?.row?.clbId?._id,
        //                   params?.row?.clbId?.assignedTo?.name
        //                 );
        //               }}
        //             >
        //               Assign
        //             </Button>
        //           )}
        //         </Box>
        //       </Stack>
        //     );
        //   },
        // },
        {
          field: "Action",
          headerName: "Action",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          // renderCell: (params) => {
          //   return (
          //     <Stack sx={{ py: 1 }}>
          //       <Box sx={{ width: "100%" }}>
          //         <Button
          //           className="gradBtn2"
          //           style={{
          //             marginRight: "10px",
          //           }}
          //           onClick={() => {
          //             navigate(`/sell/${params?.row?.clbId?._id}`);
          //           }}
          //         >
          //           Resell
          //         </Button>
          //         <Button
          //           className="gradBtn2"
          //           onClick={() => {
          //             navigate(`/explore/${params?.row?.clbId?._id}`);
          //           }}
          //         >
          //           View
          //         </Button>
          //       </Box>
          //     </Stack>
          //   );
          // },
          renderCell: (params) => {
            const isOwner = params?.row?.buyerId?._id === params?.row?.clbId?.ownerId;
            return (
              <Stack sx={{ py: 1 }}>
                <Box sx={{ width: "100%" }}>
                  {isOwner && (
                    <Button
                      className="gradBtn2"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        navigate(`/sell/${params?.row?.clbId?._id}`);
                      }}
                    >
                      Resell
                    </Button>
                  )}
                  <Button
                    className="gradBtn2"
                    onClick={() => {
                      navigate(`/explore/${params?.row?.clbId?._id}`);
                    }}
                  >
                    View
                  </Button>
                </Box>
              </Stack>
            );
          },
        },
      ]);
    } else if (CLBFilter === "Biding") {
      setColumns([
        {
          field: "clbId",
          headerName: "NFT Asset Details",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Box sx={{ p: 2 }}>
                <Box className={Style?.clbBox}>
                  {params?.row?.type == "2d" && (
                    <img src={params?.row?.files?.[0]} width={"100%"} />
                  )}
                  {params?.row?.type == "3d" && (
                    <model-viewer
                      style={{ width: "100%", height: 100 }}
                      src={params?.row?.files?.[0]}
                      ar-modes="webxr scene-viewer quick-look"
                      camera-controls
                      poster="poster.webp"
                      shadow-intensity="1"
                      disable
                    ></model-viewer>
                  )}
                </Box>
                <Typography variant="subtitle2">
                  {params?.row?.clbId?.title}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "transId",
          headerName: "Order ID/ Transaction ID",
          width: 250,
          align: "left",
          headerClassName: "super-app-theme--header",
        },
        {
          field: "price.inr",
          headerName: "Price(INR)",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          valueGetter: ({ row }) => row.price.inr,
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2">
                {params?.row?.price?.inr}
              </Typography>
            );
          },
        },
        {
          field: "price.usd",
          headerName: "Price(USD)",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          valueGetter: ({ row }) => row.price.inr,
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2">
                {/* {params?.row?.price?.usd} */}
                {convertToCurrency(params?.row?.price?.inr)}
              </Typography>
            );
          },
        },
        {
          field: "created_at",
          headerName: "Dates",
          width: 200,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Typography>{dateYYMMDD(params?.row?.created_at)}</Typography>
            );
          },
        },
        {
          field: "Order Status",
          headerName: "Order Status",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <Stack sx={{ py: 1 }}>
                <Box sx={{ width: "100%" }}>
                  {params?.row?.paid && (
                    <Box sx={{ bgcolor: "#35ABAF", p: 0.5, color: "#fff" }}>
                      Payment Successful
                    </Box>
                  )}
                  {!params?.row?.paid && (
                    <Box sx={{ bgcolor: "#FFFF49", p: 0.5, color: "#000" }}>
                      Payment Pending
                    </Box>
                  )}
                  {params?.row?.status == "Rejected" && (
                    <Box sx={{ bgcolor: "red", p: 0.5, color: "#fff" }}>
                      {params?.row?.status}
                    </Box>
                  )}
                </Box>
              </Stack>
            );
          },
        },
        {
          field: "Buy Clb",
          headerName: "Buy Clb",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            console.log("makepayment", params);
            return (
              <>
                { !params?.row?.paid && (
                  <Stack sx={{ py: 1 }}>
                    <Box sx={{ width: "100%" }}>
                      <Button
                        className="gradBtn"
                        onClick={() => {
                          if (user?.country == "India") {
                            setSelectedOrder(params?.row);
                            return setConfirmModal(true);
                          } else {
                            setBuyCLBComf(true);
                            setSelectedOrder(params?.row);
                          }
                        }}
                      >
                        Make Payment
                      </Button>
                    </Box>
                  </Stack>
                )}
              </>
            );
          },
        },
        // {
        //   field: "Mint Clb",
        //   headerName: "Mint Clb",
        //   width: 300,
        //   align: "left",
        //   headerClassName: "super-app-theme--header",
        //   renderCell: (params) => {
        //     console.log(params);
        //     if (user?.type === "Organization" && org?.status !== "Approved") {
        //       return <Typography>Not Allowed</Typography>;
        //     } else
        //       return (
        //         <>
        //           {!params?.row?.ClbId?.mint && params?.row?.paid ? (
        //             <Stack sx={{ py: 1 }}>
        //               <Box sx={{ width: "100%" }}>
        //                 <Button
        //                   className="gradBtn"
        //                   onClick={() => {
        //                     setMintConfirm(true);
        //                     setSelectedOrder(params?.row);
        //                   }}
        //                 >
        //                   Mint Now
        //                 </Button>
        //               </Box>
        //             </Stack>
        //           ) : (
        //             <>
        //               {!params?.row?.ClbId?.mint && !params?.row?.paid ? (
        //                 <p className="text-red-300">Not Owned Yet</p>
        //               ) : (
        //                 <p className="text-green-300">Minted</p>
        //               )}
        //             </>
        //           )}
        //         </>
        //       );
        //   },
        // },
        {
          field: "Action",
          headerName: "Action",
          width: 300,
          align: "left",
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            console.log(params);
            return (
              <Stack sx={{ py: 1 }}>
                <Box sx={{ width: "100%" }}>
                  <Button
                    className="gradBtn2"
                    onClick={() => {
                      navigate(`/explore/${params?.row?.clbId?._id}`);
                    }}
                  >
                    View
                  </Button>
                </Box>
              </Stack>
            );
          },
        },
      ]);
    }
  }, [CLBFilter, org]);

  // const handleKycClick = () => {
  //   dispatch(createOkycAPI());
  //   setAddKycState(true);
  // };

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Container sx={{ mt: 8, pb: 8 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4">My Orders</Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Stack direction="row" spacing={2} sx={{ p: 2 }}>
              <Button
                onClick={() => handleFilterCollectCLBs("All")}
                className={CLBFilter == "All" ? "roundBtn" : "roundBtnInActive"}
                variant="contained"
              >
                All
              </Button>
              <Button
                onClick={() => handleFilterCollectCLBs("Bought")}
                className={
                  CLBFilter == "Bought" ? "roundBtn" : "roundBtnInActive"
                }
                variant="contained"
              >
                Bought
              </Button>
              <Button
                onClick={() => handleFilterCollectCLBs("Biding")}
                className={
                  CLBFilter == "Biding" ? "roundBtn" : "roundBtnInActive"
                }
                variant="contained"
              >
                Biding
              </Button>
            </Stack>
            {/* {user?.country === "India" && !user?.kycVerified && (
              <Button
                className="roundBtn"
                onClick={handleKycClick}
                variant="contained"
              >
                Add KYC
              </Button>
            )} */}
            <Button className="roundBtn" onClick={connect} variant="contained">
              Metamask
            </Button>
          </Stack>

          <Box
            sx={{
              height: 600,
              width: "100%",
              background: "#232323",
              mt: 4,
            }}
          >
            <DataGrid
              sx={{ color: "#fff" }}
              getRowId={(row) => row._id}
              getRowHeight={() => "auto"}
              rows={result || []}
              columns={columns}
              pageSizeOptions={[25]}
              disableRowSelectionOnClick
              loading={loading && <CircularProgress />}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              slots={{
                noRowsOverlay: () => <NoDataFound />,
              }}
            />
          </Box>
        </Container>
      </Box>
      {addKycState ? (
        <AddKyc open={addKycState} setOpen={setAddKycState} />
      ) : null}

      <IndianConfirmModal
        open={confirmModal}
        setOpen={setConfirmModal}
        setBuyCLBComf={setBuyCLBComf}
      />
      <BuyCLBConfimation
        open={buyCLBComf}
        setOpen={setBuyCLBComf}
        CLB={selectedOrder?.clbId}
        setMintConfirm={setMintConfirm}
        bid={true}
        price={selectedOrder?.price}
      />
      <MintingConfirmModal
        open={mintConfirm}
        setOpen={setMintConfirm}
        setOpenKYC={setOpenKYC}
        clb={selectedOrder?.clbId}
      />

      <AddKyc open={openKYC} setOpen={setOpenKYC} />
      <Resell
        open={openResell}
        setOpen={setOpenResell}
        CLB={selectedOrder?.clbId}
      />

      {isAssigningClb && (
        <AssignClbModal
          open={isAssigningClb}
          setOpen={setIsAssigningClb}
          //   asignData={asignData}
          clbId={clbId}
        />
      )}
    </>
  );
};
export default Order;
