import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FacebookIcon from "@mui/icons-material/Facebook";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TimelineIcon from "@mui/icons-material/Timeline";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Tooltip } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import BuyCLBConfimation from "../../dialog/BuyCLBConfimation";
import { dateYYMMDD } from "../../helper/date";
import { getSIngleCLBAPI } from "../../redux/clb/getSIngleCLB";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import { followUserAPI } from "../../redux/user/followUser";
import { getSimilarClbs } from "../../service/Nft";
import { addToCart } from "../../service/cart";
import Snack from "../../ui/Snack";
import BidTable from "./BidTable";
import { CLBGraph } from "./CLBGraph";
import ChangeCLBStatus from "./ChangeCLBStatus";
import ClbHistoryTable from "./ClbHistoryTable";
import MoreLikeCLBs from "./MoreLikeCLBs";
import PlaceBid from "./PlaceBid";
import Style from "./nftdetail.module.css";
import { isFollowingUserAPI } from "../../redux/user/isFollowingUser";
import DoneIcon from "@mui/icons-material/Done";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import moment from "moment";
import ExtendCLBEndDateConfirmation from "../../dialog/ExtendCLBEndDateConfirmation";
import IndianConfirmModal from "../../dialog/IndianConfirmModal";
import UploadDocument from "../../dialog/UploadDocument";
import MintingConfirmModal from "../../dialog/MintingConfirmModal";
import AddKyc from "../orders/AddKyc";
import toast from "react-hot-toast";
import HttpService from "../../config/authAxios";
import { placeOrderAPI } from "../../redux/orders/placeOrder";
import { useSDK } from "@metamask/sdk-react";
import { getAllCartAPI } from "../../redux/cart/getAllCart";
import { getEstimatedGasFeesAPI } from "../../redux/clb/estimateGasPrice";
import getUsdInrCurrentRates from "../../helper/usdInrPriceRates";
import { useLocation } from "../../LocationContext";
import { getCurrentUserAPI } from "../../redux/user/getCurrentUser";

const SingleCLB = () => {
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: user, error: userError } = getCurrentUserSelector;
  const [confirmModal, setConfirmModal] = useState(false);
  const [latestInr, setLatestInr] = useState();

  const getSIngleCLBSelector = useSelector((state) => state?.getSIngleCLB);
  const { result, loading: CLBDetailsLoading } = getSIngleCLBSelector;
  const CLBDetail = result?.CLBs;
  // console.log("CLBDetail===", CLBDetail)
  const likesCount = result?.likesCount;

  const [tempLoading, setTempLoading] = useState(false);
  const country = localStorage.getItem("country");
  // const country = "test"
  // console.log("country", country)

  const currency = localStorage.getItem("currency");
  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const res = await getUsdInrCurrentRates();
        // console.log("Fetched INR rate:", res?.INR); // Logging for debugging
        setLatestInr(res?.INR); // Update state with INR rate
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const isFollowingUserSelector = useSelector(
    (state) => state?.isFollowingUser
  );

  const cartData = useSelector((state) => state?.getAllCart);
  const { result: isFollowResult } = isFollowingUserSelector;

  const dispatch = useDispatch();

  const { id } = useParams();
  const [active, setActive] = useState();
  const navigate = useNavigate();

  const [clbGraph, setClbGraph] = useState(false);
  const [buyCLBComf, setBuyCLBComf] = useState(false);
  const [extandCLBEndDate, setExtandCLBEndDate] = useState(false);
  const [placeBid, setPlaceBid] = useState(false);
  const [changeCLBStatus, setChangeCLBStatus] = useState(false);
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);
  const [mintConfirm, setMintConfirm] = useState(false);
  const [openKYC, setOpenKYC] = useState(false);
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const [account, setAccount] = useState("");
  const [isAdded, setIsAdded] = useState(null);
  const [isSameUser, setIsSameUser] = useState(null);

  const { convertToCurrency } = useLocation();

  // console.log("cart data", { cartData });

  const connect = async () => {
    let address;

    try {
      const accounts = await sdk?.connect();
      // console.log(accounts);
      address = accounts?.[0];
      setAccount(accounts?.[0]);
    } catch (err) {
      console.warn(`failed to connect..`, err);
    }
    return address;
  };

  const clbOrderProcessing = async (response = {}) => {
    // if (CLBs && CLBs.length > 0) {
    //   const orders = CLBs?.map((item) => ({
    //     sellerId: item?.clb?.creator?._id,
    //     clbId: item?.clb?._id,
    //     transId: response.razorpay_payment_id,
    //     price: {
    //       inr: bid ? price?.inr : item?.clb?.cost?.inr,
    //       usd: bid ? price?.usd : item?.clb?.cost?.usd,
    //     },
    //     status: "Successfull",
    //   }));

    //   await dispatch(placeMultipleOrdersAPI({ orders }));
    //   await onClearCart();
    //   window.location.reload();
    // } else {
    // console.log("CLBDETAIL: ", CLBDetail);
    console.log(">>>>>>>>>>>>>>>..CHECK: ", {
      sellerId: CLBDetail?.creatorId,
      clbId: CLBDetail?._id,
      transId:
        response.razorpay_payment_id ??
        localStorage.getItem("Coingate-orderId"),
      price: {
        inr: CLBDetail?.cost?.inr,
        usd: CLBDetail?.cost?.usd,
      },
      status: "Successfull",
    });
    dispatch(
      placeOrderAPI({
        sellerId: CLBDetail?.creatorId,
        clbId: CLBDetail?._id,
        transId: localStorage.getItem("Coingate-orderId"),
        price: {
          inr: CLBDetail?.cost?.inr,
          usd: CLBDetail?.cost?.usd,
        },
        status: "Successfull",
      })
    );
    // }

    localStorage.removeItem("Coingate-orderId");

    if (!error) {
      // setOpen(false);
      const address = await connect();
      // console.log("address", address);
      // if (!CLBDetail?.mint) {
      //   setMintConfirm(true);
      // } else if (CLBDetail?.mint && address) {
      //   try {
      //     const { data } = await HttpService.post("/nft/transfer", {
      //       CLBId: CLBDetail?._id,
      //       walletAddress: address,
      //     });
      //     console.log("data", data);

      //     toast.success("NFT successfully transferred to your wallet", {
      //       duration: 5000,
      //       position: "bottom-left",
      //     });
      //   } catch (error) {
      //     console.log("error", error);
      //     toast.error("Payment Successful, but NFT transfer failed", {
      //       duration: 5000,
      //       position: "bottom-left",
      //     });
      //   }
      // }
    }

    if (user?.country === "India") {
      if (!user?.kycVerified) {
        toast.success(
          "Payment Successful, Please complete your KYC and connect your metamask wallet",
          {
            duration: 5000,
            position: "bottom-left",
          }
        );
      } else {
        toast.success("Payment Successful", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } else {
      if (!user?.metamaskConnected) {
        toast.success(
          "Payment Successful, Please connect your metamask wallet",
          {
            duration: 5000,
            position: "bottom-left",
          }
        );
      } else {
        toast.success("Payment Successful", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    }

    return;
  };

  const coingateOrderActionHandler = async () => {
    try {
      const orderId = localStorage.getItem("Coingate-orderId");
      const response = await HttpService.get(
        `/payments/coingate-success/${orderId}`
      );
      if (response.data.status === "paid") {
        toast.success("Payment successful!", {
          duration: 5000,
          position: "bottom-left",
        });
        // localStorage.removeItem("Coingate-orderId");
        if (CLBDetail) {
          clbOrderProcessing();
        }
      } else if (
        response.data.status === "canceled" ||
        response.data.status === "expired" ||
        response.data.status === "invalid"
      ) {
        toast.error("Payment failed or expired.", {
          duration: 5000,
          position: "bottom-left",
        });
        localStorage.removeItem("Coingate-orderId");
      }
    } catch (error) {
      console.error("Error checking payment status:", error.message);
      localStorage.removeItem("Coingate-orderId");
    }
  };

  useEffect(() => { 
    if (CLBDetail && localStorage.getItem("Coingate-orderId")) {
      coingateOrderActionHandler();
    }

    const userId = localStorage.getItem("userId");
    console.log("dfghjkfghjkfghjk:", {
      userId,
      CLBDetail,
    });
    setIsSameUser(CLBDetail?.ownerId === userId);
  }, [CLBDetail]);

  const today = moment(moment(new Date()).subtract(1, "days")?._d).format();

  const handleFollow = async (id) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    await dispatch(
      followUserAPI({ followingrId: CLBDetail?.creatorId, isFollowed: true })
    ).unwrap();
    await dispatch(
      isFollowingUserAPI({ followingrId: CLBDetail?.creatorId })
    ).unwrap();
  };

  const handleUnFollow = async (id) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    await dispatch(
      followUserAPI({ followingrId: CLBDetail?.creatorId, isFollowed: false })
    ).unwrap();
    await dispatch(
      isFollowingUserAPI({ followingrId: CLBDetail?.creatorId })
    ).unwrap();
  };

  useEffect(() => {
    const apiData = async () => {
      const { data } = await getSimilarClbs(id);
    };
    dispatch(getAllCartAPI());
    apiData();
  }, [id]);

  useEffect(() => {
    if (CLBDetail && cartData?.result?.length > 0) {
      const clbId = CLBDetail._id;
      const cardDataDetail = cartData.result.map((ele) => ele.clb._id);
      setIsAdded(cardDataDetail.includes(clbId));
    }
  }, [cartData, CLBDetail]);

  const hanldeAddtoCart = (e) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    e.preventDefault();
    setTempLoading(true);
    addToCart(id)
      .then(({ data }) => {
        setIsAdded(true); // Set item as added
        setTempLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTempLoading(false);
      });

    setIsAdded(!isAdded);
  };

  const handleBuyCLBs = async (clb, e) => {
    if (country != "India" && !user?.identityDocument)
      return e.preventDefault();
    if (userError) {
      return setUserNotLoggedIn(true);
    }

    if (user?.country == "India") {
      return setConfirmModal(true);
    }

    setBuyCLBComf(true);
  };

  const handlePlaceBid = () => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    setPlaceBid(true);
  };

  const handleExtendBiddingDate = () => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    setExtandCLBEndDate(true);
  };

  const handleFavirote = async (fav, clbId, creatorId) => {
    // console.log("fav==========>", fav);
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    await dispatch(
      changeCLBsFavStatusAPI({ isFavorite: fav, clbId, creatorId })
    );
    await dispatch(getSIngleCLBAPI(id));
  };

  const hadleUserprofile = (creatorId) => {
    if (userError) {
      return setUserNotLoggedIn(true);
    }
    navigate(`/profile/${creatorId}`);
  };

  useEffect(() => {
    const rzPayElem = document.createElement("script");
    rzPayElem.src = "https://checkout.razorpay.com/v1/checkout.js";
    rzPayElem.async = true;
    document.body.appendChild(rzPayElem);
  }, []);

  useEffect(() => {
    if (CLBDetail?.creatorId) {
      dispatch(isFollowingUserAPI({ followingrId: CLBDetail?.creatorId }));
    }
  }, [CLBDetail?.creatorId]);

  useEffect(() => {
    dispatch(getSIngleCLBAPI(id));
  }, [id]);

  const [expanded, setExpanded] = useState(false);
  //transfer fee api

  const getEstimatedGasFeesSelector = useSelector(
    (state) => state?.getEstimatedGasFees
  );
  const { result: gasFees } = getEstimatedGasFeesSelector;

  // const exchangeRateUsdToInr = 83;

  // const isAdminOrSuperAdmin = ["Admin", "SuperAdmin"].includes(
  //   CLBDetail?.createdByType
  // );
  // console.log("admin", isAdminOrSuperAdmin);
  // const isFirstNFT = CLBDetail?.isFirstNFT;
  // console.log("isfirst", isFirstNFT);

  // // Calculate gas fees based on conditions
  // const gasFeeMultiplier = isAdminOrSuperAdmin && isFirstNFT ? 3 : 1;
  // console.log("gasFee", gasFeeMultiplier);
  // const calculatedGasFees = (+gasFees?.UsdPrice * gasFeeMultiplier).toFixed(2);
  // console.log("calculated", calculatedGasFees);
  // const calculatedGasFeesInInr = (calculatedGasFees * latestInr).toFixed(2);
  const isAdminOrSuperAdmin = ["Admin", "SuperAdmin"].includes(
    CLBDetail?.createdByType
  );
  console.log("admin", isAdminOrSuperAdmin);

  const isFirstNFT = !CLBDetail?.bought && !CLBDetail?.reselling; // Check if bought and reselling are both false
  console.log("isfirst", isFirstNFT);

  // Calculate gas fees based on new conditions
  const gasFeeMultiplier = isAdminOrSuperAdmin && isFirstNFT ? 3 : 1;
  console.log("gasFee", gasFeeMultiplier);

  const calculatedGasFees = (+gasFees?.UsdPrice * gasFeeMultiplier).toFixed(2);
  console.log("calculated", calculatedGasFees);

  const calculatedGasFeesInInr = (calculatedGasFees * latestInr).toFixed(2);
  console.log("calculated in INR", calculatedGasFeesInInr);

  useEffect(() => {
    dispatch(getEstimatedGasFeesAPI());
  }, []);

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Container>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box className="singleCLBCard">
                <Stack
                  alignItems="center"
                  justifyContent="flex-end"
                  direction="row"
                >
                  <Card className="grayCard">
                    {CLBDetail?.favorites ? (
                      <IconButton
                        onClick={() =>
                          handleFavirote(
                            false,
                            CLBDetail?._id,
                            CLBDetail.creatorId
                          )
                        }
                      >
                        <FavoriteIcon sx={{ color: "primary.main" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleFavirote(true, CLBDetail?._id)}
                      >
                        <FavoriteBorderIcon sx={{ color: "primary.main" }} />
                      </IconButton>
                    )}
                    <Typography>{likesCount}</Typography>
                  </Card>
                </Stack>
                <Box className="singleCLBBox">
                  {CLBDetail?.type == "2d" && (
                    <img src={CLBDetail?.files?.[0]} width={"100%"} />
                  )}
                  {CLBDetail?.type == "3d" && (
                    <model-viewer
                      style={{ width: "100%", height: "500px" }}
                      src={CLBDetail?.files?.[0]}
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      camera-controls
                      poster="poster.webp"
                      shadow-intensity="1"
                      disable
                    ></model-viewer>
                  )}
                </Box>

                <Grid container>
                  <Grid lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="h5" fontWeight={700}>
                      {CLBDetail?.title}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        onClick={() => hadleUserprofile(CLBDetail?.creatorId)}
                      >
                        <Avatar src={CLBDetail?.creatorPicture} />
                      </IconButton>
                      <Typography>{CLBDetail?.creatorName}</Typography>
                    </Stack>
                  </Grid>

                  <Grid lg={6} md={6} sm={12} xs={12}>
                    <Stack justifyContent="flex-end" direction="row">
                      <IconButton>
                        <a
                          href={CLBDetail?.createrSocial?.instagram}
                          target="_blank"
                        >
                          <InstagramIcon sx={{ color: "#fff" }} />
                        </a>
                      </IconButton>

                      <IconButton>
                        <a
                          href={CLBDetail?.createrSocial?.facebook}
                          target="_blank"
                        >
                          <FacebookIcon sx={{ color: "#fff" }} />
                        </a>
                      </IconButton>

                      <IconButton>
                        <a
                          href={CLBDetail?.createrSocial?.linkedin}
                          target="_blank"
                        >
                          <LinkedInIcon sx={{ color: "#fff" }} />
                        </a>
                      </IconButton>
                      <IconButton>
                        <a
                          href={CLBDetail?.createrSocial?.twitter}
                          target="_blank"
                        >
                          <TwitterIcon sx={{ color: "#fff" }} />
                        </a>
                      </IconButton>
                      <IconButton>
                        <a
                          href={CLBDetail?.createrSocial?.youtube}
                          target="_blank"
                        >
                          <YouTubeIcon sx={{ color: "#fff" }} />
                        </a>
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              {CLBDetail?.isBidAble ? <BidTable /> : null}
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={5}
                >
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    sx={{
                      padding: "8px 16px",
                      whiteSpace: "wrap",
                      // overflow : "hidden",
                      maxWidth: "350px",
                    }}
                    title={CLBDetail?.title}
                  >
                    {CLBDetail?.title}
                  </Typography>
                  {CLBDetailsLoading ? (
                    <CircularProgress />
                  ) : CLBDetail?.creatorId === user?._id ? (
                    <>
                      {CLBDetail?.active ? (
                        <Button
                          endIcon={<ArrowRightAltIcon />}
                          variant="outlined"
                          onClick={() => {
                            setChangeCLBStatus(true);
                            setActive(false);
                          }}
                        >
                          In-Active
                        </Button>
                      ) : (
                        <Button
                          endIcon={<ArrowRightAltIcon />}
                          variant="contained"
                          onClick={() => {
                            setChangeCLBStatus(true);
                            setActive(true);
                          }}
                          sx={{
                            padding: "8px 16px",
                            whiteSpace: "nowrap",
                            minWidth: "150px",
                          }}
                        >
                          Active
                        </Button>
                      )}
                    </>
                  ) : isFollowResult?.isFollowed ? (
                    <Button
                      variant="contained"
                      onClick={() => handleUnFollow(CLBDetail?.creatorId)}
                      endIcon={<DoneIcon />}
                    >
                      Following
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => handleFollow(CLBDetail?.creatorId)}
                    >
                      Follow
                    </Button>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TableContainer>
                    <Table aria-label="clb table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: "20%", padding: "0px !important" }}
                            component="th"
                            scope="row"
                          >
                            <Typography>Artist</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "80%", padding: "0px !important" }}
                          >
                            <Typography color="primary">
                              {CLBDetail?.creatorName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TableContainer>
                    <Table aria-label="clb table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: "20%", padding: "0px !important" }}
                            component="th"
                            scope="row"
                          >
                            <Typography>Category</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "80%", padding: "0px !important" }}
                          >
                            <Typography
                              color="primary"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px",
                                cursor: "default",
                              }}
                              title={CLBDetail?.categoryTitle}
                            >
                              {CLBDetail?.categoryTitle}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                {CLBDetail?.isBidAble ? (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <TableContainer>
                      <Table aria-label="clb table">
                        <TableBody>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{ width: "20%", padding: "0px !important" }}
                              component="th"
                              scope="row"
                            >
                              <Typography>Expiry</Typography>
                            </TableCell>
                            <TableCell
                              sx={{ width: "80%", padding: "0px !important" }}
                            >
                              <Typography color="primary">
                                {dateYYMMDD(CLBDetail?.endDate)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                ) : null}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TableContainer>
                    <Table aria-label="clb table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: "20%", padding: "0px !important" }}
                            component="th"
                            scope="row"
                          >
                            <Typography>Link</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "80%", padding: "0px !important" }}
                          >
                            <a
                              className="link"
                              href={CLBDetail?.link}
                              target="_blank"
                              style={{ textDecoration: "underline" }}
                            >
                              <Typography color="primary">
                                {CLBDetail?.link}
                              </Typography>
                            </a>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TableContainer>
                    <Table aria-label="clb table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: "20%", padding: "0px !important" }}
                            component="th"
                            scope="row"
                          >
                            <Typography>Description</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "80%", padding: "0px !important" }}
                          >
                            <Link className="link">
                              <Typography
                                color="primary"
                                sx={{
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  overflow: expanded ? "overflow" : "hidden",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: expanded ? "unset" : " 2",
                                  textOverflow: "ellipsis",
                                  cursor: "default",
                                }}
                              >
                                {CLBDetail?.description}
                              </Typography>
                              {CLBDetail?.description?.length > 250 && (
                                <Button
                                  variant="text"
                                  onClick={() => setExpanded(!expanded)}
                                  sx={{
                                    padding: 0,
                                    marginTop: 1,
                                    textTransform: "none",
                                  }}
                                >
                                  {expanded ? "Show Less" : "View More"}
                                </Button>
                              )}
                            </Link>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>

                <Stack spacing={1}>
                  <TableContainer>
                    <Table aria-label="clb table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: "20%", padding: "0px !important" }}
                            component="th"
                            scope="row"
                          >
                            <Typography>Properties</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "120%", padding: "0px !important" }}
                          >
                            <Grid container>
                              {CLBDetail?.property?.map((item, idx) => {
                                return (
                                  <>
                                    <Grid
                                      item
                                      key={idx}
                                      lg={6}
                                      md={4}
                                      sm={2}
                                      xs={1}
                                      m={1}
                                    >
                                      <Card
                                        className="propertyCard"
                                        sx={{
                                          width: "100%",
                                          height: "220px",
                                          padding: "10px",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                          boxShadow: 3,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: "bold", // Make the name stand out
                                            overflow: "auto",
                                          }}
                                        >
                                          {item?.name}
                                        </Typography>
                                        <Typography
                                          color="primary"
                                          sx={{
                                            overflow: "auto",
                                          }}
                                        >
                                          {item?.type}
                                        </Typography>
                                      </Card>
                                    </Grid>
                                  </>
                                );
                              })}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack spacing={1}>
                  <TableContainer>
                    <Table aria-label="clb table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ width: "20%", padding: "0px !important" }}
                            component="th"
                            scope="row"
                          >
                            <Typography>Minted</Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "80%", padding: "0px !important" }}
                          >
                            <Typography variant="p" color="primary">
                              {CLBDetail?.mint ? "Yes" : "No"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack direction="row" spacing={2}>
                  {CLBDetailsLoading ? (
                    <CircularProgress />
                  ) : CLBDetail?.ownerId == user?._id &&
                    CLBDetail?.isBidAble ? (
                    <>
                      <Link to={CLBDetail?.files?.[0]} target="_blank" download>
                        <Button className="gradBtn2">Download</Button>
                      </Link>
                      {today >
                        moment(
                          moment(CLBDetail?.endDate).subtract(1, "days")?._d
                        ).format() && (
                        <Button
                          className="gradBtn2"
                          onClick={handleExtendBiddingDate}
                        >
                          Extend End Date
                        </Button>
                      )}
                      {CLBDetail?.stage == "Purchased" && (
                        <Button
                          variant="contained"
                          onClick={() => navigate(`/sell/${CLBDetail?._id}`)}
                        >
                          Sell NFT
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {isSameUser === true ? (
                        <Link
                          to={CLBDetail?.files?.[0]}
                          target="_blank"
                          download
                        >
                          <Button className="gradBtn2">Download</Button>
                        </Link>
                      ) : CLBDetail?.orderStatus == "Successfull" && (CLBDetail?.reselling != CLBDetail?.bought) ? (
                        <Button
                          className="gradBtn2"
                          endIcon={<DoneIcon />}
                          onClick={() => navigate("/orders")}
                        >
                          Sold Out
                        </Button>
                      ) :CLBDetail?.isBidAble ? (
                        new Date(CLBDetail.endDate).getTime() <
                        new Date().getTime() ? (
                          <Button
                            className="gradBtn2"
                            style={{ color: "#fff" }}
                            disabled
                          >
                            Ended
                          </Button>
                        ) : (
                          <Button className="gradBtn2" onClick={handlePlaceBid}>
                            Bid
                          </Button>
                        )
                      ) : (
                        // Show Collect button if reselling and bought are both true or both false
                        CLBDetail?.reselling === CLBDetail?.bought && (
                          <>
                            <Tooltip
                              title={
                                <Typography className="text-xs">
                                  Gas Fee: {currency}{" "}
                                  {currency === "₹"
                                    ? calculatedGasFeesInInr
                                    : calculatedGasFees}
                                  <br />
                                  NFT Fees: {currency}{" "}
                                  {convertToCurrency(CLBDetail?.cost?.inr)}
                                </Typography>
                              }
                              arrow
                              placement="top"
                            >
                              <Button
                                variant="contained"
                                onClick={(e) => handleBuyCLBs(CLBDetail, e)}
                                style={{
                                  backgroundColor:
                                    country !== "India" &&
                                    !user?.identityDocument
                                      ? "gray"
                                      : "",
                                }}
                              >
                                <Tooltip
                                  title={
                                    country !== "India" &&
                                    !user?.identityDocument
                                      ? "Please upload your passport or driving license."
                                      : ""
                                  }
                                >
                                  <span>
                                    Collect at {currency}{" "}
                                    {currency === "₹"
                                      ? (
                                          (+CLBDetail?.cost?.inr || 0) +
                                          (+calculatedGasFeesInInr || 0)
                                        ).toFixed(2)
                                      : (
                                          (+convertToCurrency(
                                            CLBDetail?.cost?.inr
                                          ) || 0) + (+calculatedGasFees || 0)
                                        ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              </Button>
                            </Tooltip>
                            <UploadDocument country={country} user={user} />
                            {tempLoading ? (
                              <CircularProgress />
                            ) : isAdded ? (
                              <Button className="gradBtn2">Added</Button>
                            ) : (
                              <Button
                                className="gradBtn2"
                                onClick={hanldeAddtoCart}
                              >
                                Add to Cart
                              </Button>
                            )}
                            {/* 
            <Button className="gradBtn2" onClick={hanldeAddtoCart}>
              Add to Cart
            </Button> */}
                          </>
                        )
                      )}
                    </>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="h4" sx={{ color: "#fff" }}>
                    History
                  </Typography>
                  <IconButton onClick={() => setClbGraph(true)}>
                    <TimelineIcon color="primary" />
                  </IconButton>
                </Stack>
                <ClbHistoryTable />
              </Stack>
            </Grid>
          </Grid>
        </Container>

        <MoreLikeCLBs CLB={CLBDetail} />
      </Box>

      {clbGraph ? <CLBGraph open={clbGraph} setOpen={setClbGraph} /> : null}
      {buyCLBComf ? (
        <BuyCLBConfimation
          open={buyCLBComf}
          setOpen={setBuyCLBComf}
          CLB={CLBDetail}
          setMintConfirm={setMintConfirm}
        />
      ) : null}
      {extandCLBEndDate ? (
        <ExtendCLBEndDateConfirmation
          open={extandCLBEndDate}
          setOpen={setExtandCLBEndDate}
          CLB={CLBDetail}
        />
      ) : null}
      {placeBid ? (
        <PlaceBid open={placeBid} setOpen={setPlaceBid} CLB={CLBDetail} />
      ) : null}
      {changeCLBStatus ? (
        <ChangeCLBStatus
          open={changeCLBStatus}
          setOpen={setChangeCLBStatus}
          CLB={CLBDetail}
          active={active}
        />
      ) : null}
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
      <IndianConfirmModal
        open={confirmModal}
        setOpen={setConfirmModal}
        setBuyCLBComf={setBuyCLBComf}
      />

      {/* <MintingConfirmModal
        open={mintConfirm}
        setOpen={setMintConfirm}
        setOpenKYC={setOpenKYC}
      /> */}
      <AddKyc open={openKYC} setOpen={setOpenKYC} />
    </>
  );
};
export default SingleCLB;
