import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Header from "../../components/header/Header";
import { createCLBAPI } from "../../redux/clb/createCLB";
import { getSIngleCLBAPI } from "../../redux/clb/getSIngleCLB";
import Style from "../../styles/clb.module.scss";
import { sellPurchasedCLBAPI } from "../../redux/clb/sellPurchasedCLB";
import Snack from "../../ui/Snack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import HttpService from "../../config/authAxios";
const schema = yup
  .object({
    title: yup.string().required("required field"),
    link: yup.string(),
    description: yup.string().required("required field"),
    type: yup.string(),
  })
  .required();

const SellNFT = () => {
  const getSIngleCLBSelector = useSelector((state) => state?.getSIngleCLB);
  const { result: getSIngleCLBResult } = getSIngleCLBSelector;

  const sellPurchasedCLBSelector = useSelector(
    (state) => state?.sellPurchasedCLB
  );
  const { message, error } = getSIngleCLBSelector;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });
  const [expDate, setExpDate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { CLBId } = useParams();
  const [isPaymentDetailsSubmitted, setIsPaymentDetailsSubmitted] =
    useState(false);

  const [isBidAble, setIsBidAble] = useState("Bid");
  const [cost, setCost] = useState({ inr: null, usd: null });
  const [costComp, setCostComp] = useState({ inr: null, usd: null });
  const [royaltyFee, setRoyaltyFee] = useState({
    percentage: null,
    INR: null,
    USD: null,
  });
  const [userId, setUserId] = useState("");
  const [userLocation, setUserLocation] = useState();
  const [userData, setUserData] = useState({});
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [cryptoFields, setCryptoFields] = useState({ walletAddress: "" });
  const [inrFields, setInrFields] = useState({
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountType: "",
    branchName: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleCreateCLB = async (data) => {
   
    if (!costComp?.inr) {
      return setError("cost.inr", {
        type: "custom",
        message: "Price (INR) can not be empty!",
      });
    }
    if (
      !isPaymentDetailsSubmitted &&
      !userData?.payout
    ) {
      setOpenPaymentPopup(true);
      return;
    }
   

    let isBidAbleType;
    if (isBidAble == "Bid") isBidAbleType = true;
    else isBidAbleType = false;

    const clb = await dispatch(
      sellPurchasedCLBAPI({
        isBidAble: isBidAbleType,
        cost: { inr: Number(costComp?.inr), usd: Number(costComp?.usd) },

        endDate: expDate,
        CLBId,
      })
    );

    navigate(`/explore/${CLBId}`);
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const userLocation = localStorage.getItem("country");
    setUserLocation(userLocation);
    if (storedUserId) setUserId(storedUserId);
  }, []);

  useEffect(() => {
    if (userId) {
      const getPaymentInfo = async () => {
        try {
          const response = await HttpService.post(`/users/getUserById`, {
            userId,
          });
          setUserData(response?.data?.result);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };

      getPaymentInfo();
    }
  }, [userId]);

  const handlePriceCom = async () => {
    let res = await axios.get(
      `https://openexchangerates.org/api/latest.json?app_id=3118004813b1465ebb7f3b57e1f160b6&base=USD`
    );
    const priceCompData = res?.data;
    // const USD = priceCompData?.rates?.USD
    const INR = priceCompData?.rates?.INR;
    setCostComp({ inr: cost?.inr, usd: cost?.inr / INR });
    setRoyaltyFee({
      INR:
        (costComp?.inr * getSIngleCLBResult?.CLBs?.royaltyFee?.percentage) /
        100,
      USD:
        (costComp?.usd * getSIngleCLBResult?.CLBs?.royaltyFee?.percentage) /
        100,
    });
  };

  useEffect(() => {
    handlePriceCom();
  }, [cost]);
  useEffect(() => {
    dispatch(getSIngleCLBAPI(CLBId));
  }, [CLBId]);

  useEffect(() => {
    let defaultValues = {};
    defaultValues = {
      title: getSIngleCLBResult?.CLBs?.title,
      description: getSIngleCLBResult?.CLBs?.description,
      link: getSIngleCLBResult?.CLBs?.linke,
      type: getSIngleCLBResult?.CLBs?.type,
    };

    reset({ ...defaultValues });
  }, [getSIngleCLBResult]);

  useEffect(() => {
    setRoyaltyFee({
      percentage: getSIngleCLBResult?.CLBs?.royaltyFee?.percentage,
      INR: getSIngleCLBResult?.CLBs?.royaltyFee?.INR,
      USD: getSIngleCLBResult?.CLBs?.royaltyFee?.USD,
    });
  }, [getSIngleCLBResult]);

  const submitPaymentDetails = async () => {
    const payload = selectedPaymentType === "crypto"
      ? {
          id: userId,
          payout: "crypto",
          cryptoWallet: { walletAddress: cryptoFields.walletAddress },
        }
      : {
          id: userId,
          payout: "inr",
          bankDetail: inrFields,
          bankName: inrFields.bankName,
          branchName: inrFields.branchName,
          accountType: inrFields.accountType,
        };
  
    try {
      const response = await HttpService.post("/users/bankDetails", payload);
      if (response) {
        setOpenPaymentPopup(false);
        setSelectedPaymentType("");
        
        // After submitting payment details, fetch updated user data
        const userResponse = await HttpService.post(`/users/getUserById`, {
          userId,
        });
        
        if (userResponse?.data?.result) {
          debugger
          setUserData(userResponse?.data?.result);
          
          // Now check if we have payment details and proceed with sell
          // console.log()
          // if (userResponse?.data?.result?.payout) {
            // await handleCreateCLB();
          // }
        }
      }
    } catch (error) {
      console.error("Error submitting payment details:", error);
    }
  };
  // const submitPaymentDetails = async () => {
  //   const payload =
  //     selectedPaymentType === "crypto"
  //       ? {
  //           id: userId,
  //           payout: "crypto",
  //           cryptoWallet: { walletAddress: cryptoFields.walletAddress },
  //         }
  //       : {
  //           id: userId,
  //           payout: "inr",
  //           bankDetail: inrFields,
  //           bankName: inrFields.bankName,
  //           branchName: inrFields.branchName,
  //           accountType: inrFields.accountType,
  //         };

  //   try {
  //     const response = await HttpService.post("/users/bankDetails", payload);
  //     console.log("bank", response);
  //     if (response) {
  //       setOpenPaymentPopup(false);
  //       setSelectedPaymentType("");
  //       setIsPaymentDetailsSubmitted(true);
  //       // await handleSubmit(handleCreateCLB)();
  //     }
  //   } catch (error) {
  //     console.error("Error submitting payment details:", error);
  //   }
  // };

  useEffect(() => {
    const validCrypto = cryptoFields.walletAddress.trim();
    const validINR =
      inrFields.accountNumber.trim() &&
      inrFields.ifscCode.trim() &&
      inrFields.bankName.trim() &&
      inrFields.branchName.trim();

    setDisableSubmit(
      selectedPaymentType === "crypto" ? !validCrypto : !validINR
    );
  }, [cryptoFields, inrFields, selectedPaymentType]);

  // const handleSellClick = async () => {
  //   console.log("userData", userData)
  //   if (userData?.bankDetail === undefined || userData.bankDetail === null) {
  //     setOpenPaymentPopup(true);
  //     return;
  //   }
  //   await handleSubmit(handleCreateCLB)();
  // };
  const handleSellClick = async () => {
    debugger
    console.log("userData", userData);

    if (userData?.payout === "inr") {
      if (!userData?.bankDetail) {
        setOpenPaymentPopup(true);
        return;
      }
    } else if (userData?.payout === "crypto") {
      if (!userData?.cryptoWallet?.walletAddress) {
        setOpenPaymentPopup(true);
        return;
      }
    } else {
      setOpenPaymentPopup(true);
      return;
    }

    await handleSubmit(handleCreateCLB)();
  };

  return (
    <>
      <Box className={Style.main}>
        <Header />
        <Box className={Style.container}>
          <Grid container justifyContent="center" sx={{ p: 2 }}>
            <Grid lg={5} md={7} sm={9} xs={12}>
              <Card>
                <form onSubmit={handleSubmit(handleCreateCLB)}>
                  <Stack spacing={4}>
                    <Stack alignItems="center" spacing={1}>
                      <Typography color="primary" variant="h5">
                        Sell CLB
                      </Typography>
                    </Stack>

                    <Card>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: 300 }}
                      >
                        {getSIngleCLBResult?.CLBs?.type == "2d" && (
                          <img
                            src={getSIngleCLBResult?.CLBs?.files?.[0]}
                            height={"100%"}
                          />
                        )}
                        {getSIngleCLBResult?.CLBs?.type == "3d" && (
                          <model-viewer
                            style={{ width: "100%", height: 250 }}
                            src={getSIngleCLBResult?.CLBs?.files?.[0]}
                            ar-modes="webxr scene-viewer quick-look"
                            camera-controls
                            poster="poster.webp"
                            shadow-intensity="1"
                            disable
                          ></model-viewer>
                        )}
                      </Stack>
                    </Card>

                    <TextField label="Title" {...register("title")} disabled />

                    <FormControl>
                      <TextField
                        type="number"
                        label="Price (in INR)"
                        value={cost?.inr}
                        onChange={({ target }) =>
                          setCost({ ...cost, inr: target?.value })
                        }
                      />
                      <FormHelperText error>
                        {errors?.cost?.inr?.message}
                      </FormHelperText>
                    </FormControl>

                    <TextField
                      type="number"
                      label="Price (in USD)"
                      value={cost?.usd || costComp?.usd}
                      disabled
                    />
                    <TextField
                      type="number"
                      label="Royalty Percentage"
                      value={royaltyFee?.percentage}
                      disabled
                    />
                    <TextField
                      type="number"
                      label="Royalty Fee(INR)"
                      value={royaltyFee?.INR}
                      disabled
                    />
                    <TextField
                      type="number"
                      label="Royalty Fee(USD)"
                      value={royaltyFee?.USD}
                      disabled
                    />

                    <FormControl>
                      <RadioGroup
                        defaultValue={isBidAble}
                        onChange={({ target }) => setIsBidAble(target.value)}
                      >
                        <Stack direction="row" alignItems="center">
                          <FormControlLabel
                            sx={{ color: "#fff" }}
                            value="Bid"
                            control={<Radio />}
                            label="Bid"
                          />
                          <FormControlLabel
                            sx={{ color: "#fff" }}
                            value="Purchase"
                            control={<Radio />}
                            label="Purchase"
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    <FormControl>
                      {isBidAble == "Bid" ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ bgcolor: "#484848", borderRadius: 2 }}
                            label="Expiry Date"
                            slotProps={{ textField: { size: "small" } }}
                            minDate={new Date()}
                            onChange={(value) => {
                              setExpDate(moment(value).format("YYYY-MM-DD"));
                            }}
                          />
                        </LocalizationProvider>
                      ) : null}
                      <FormHelperText error>
                        {errors?.expDateError?.message}
                      </FormHelperText>
                    </FormControl>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                      {getSIngleCLBResult?.CLBs?.property?.length > 0 && (
                        <Typography variant="p" sx={{ color: "#fff" }}>
                          Properties
                        </Typography>
                      )}

                      {getSIngleCLBResult?.CLBs?.property.map((field, idx) => {
                        return (
                          <>
                            <Stack
                              direction="row"
                              spacing={1}
                              key={idx}
                              alignItems="center"
                            >
                              <Chip
                                variant="outlined"
                                color="primary"
                                label={field?.name}
                              />
                              <Chip
                                variant="outlined"
                                color="primary"
                                label={field?.type}
                              />
                            </Stack>
                          </>
                        );
                      })}
                    </Stack>
                    <Button
                      onClick={handleSellClick}
                      type="submit"
                      variant="contained"
                    >
                      Sell
                    </Button>
                  </Stack>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openPaymentPopup}
          onClose={() => setOpenPaymentPopup(false)}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiDialog-paper": {
              padding: 3,
              borderRadius: 2,
              backgroundColor: "#2c2c2c", // Dark grey background for better contrast
              color: "#ffffff", // White text color
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.4)", // Add shadow for focus
            },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#ffffff", // White text for the title
            }}
          >
            {selectedPaymentType
              ? selectedPaymentType === "crypto"
                ? "Add Wallet Address"
                : "Add Bank Details"
              : "Select Payment Method"}
          </DialogTitle>
          <DialogContent>
            {!selectedPaymentType ? (
              <Stack
                direction="row"
                spacing={3}
                justifyContent="center"
                sx={{ marginTop: 2 }}
              >
                <Button
                  variant="contained"
                  onClick={() => setSelectedPaymentType("crypto")}
                  sx={{
                    padding: 2,
                    fontSize: "1rem",
                  }}
                >
                  Pay with Crypto
                </Button>
                {userLocation ? (
                  userLocation == "India" ? (
                    <Button
                      variant="contained"
                      onClick={() => setSelectedPaymentType("inr")}
                      sx={{
                        padding: 2,
                        fontSize: "1rem",
                      }}
                    >
                      Pay with INR
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => setSelectedPaymentType("inr")}
                    sx={{
                      padding: 2,
                      fontSize: "1rem",
                    }}
                  >
                    Pay with INR
                  </Button>
                )}
              </Stack>
            ) : selectedPaymentType === "crypto" ? (
              <>
                <TextField
                  fullWidth
                  label={
                    <>
                      "Wallet Address" <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={cryptoFields.walletAddress}
                  onChange={(e) =>
                    setCryptoFields({
                      ...cryptoFields,
                      walletAddress: e.target.value,
                    })
                  }
                  error={!!errors.walletAddress}
                  helperText={errors.walletAddress}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" }, // Light grey label color
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c", // Darker input background
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555", // Medium grey border
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888", // Lighter grey on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2", // Blue border on focus
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff", // White input text
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc", // Light red for error messages
                    },
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  label={
                    <>
                      "Account Holder Name"{" "}
                      <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={inrFields.name}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, name: e.target.value })
                  }
                  error={!!errors.name}
                  helperText={errors.name}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c",
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc",
                    },
                  }}
                />
                <TextField
                  type="number"
                  fullWidth
                  label={
                    <>
                      "Account Number" <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={inrFields.accountNumber}
                  onChange={(e) =>
                    setInrFields({
                      ...inrFields,
                      accountNumber: e.target.value,
                    })
                  }
                  error={!!errors.accountNumber}
                  helperText={errors.accountNumber}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c",
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label={
                    <>
                      "IFSC Code" <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={inrFields.ifscCode}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, ifscCode: e.target.value })
                  }
                  error={!!errors.ifscCode}
                  helperText={errors.ifscCode}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c",
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label={
                    <>
                      "Bank Name" <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={inrFields.bankName}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, bankName: e.target.value })
                  }
                  error={!!errors.bankName}
                  helperText={errors.bankName}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c",
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label={
                    <>
                      "Branch Name" <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={inrFields.branchName}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, branchName: e.target.value })
                  }
                  error={!!errors.branchName}
                  helperText={errors.branchName}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c",
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc",
                    },
                  }}
                />

                <TextField
                  fullWidth
                  label={
                    <>
                      "Account Type" <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={inrFields.accountType}
                  onChange={(e) =>
                    setInrFields({ ...inrFields, accountType: e.target.value })
                  }
                  error={!!errors.bankName}
                  helperText={errors.bankName}
                  sx={{
                    mt: 2,
                    "& .MuiInputLabel-root": { color: "#cccccc" },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#1c1c1c",
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#ffffff",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#ffcccc",
                    },
                  }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between", mt: 3 }}>
            <Button
              onClick={() => {
                setOpenPaymentPopup(false);
                setSelectedPaymentType("");
                setInrFields({
                  accountNumber: "",
                  ifscCode: "",
                  bankName: "",
                  accountType: "",
                  branchName: "",
                });
              }}
              sx={{
                color: "#ffffff", // White cancel button text
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                submitPaymentDetails();
              }}
              disabled={disableSubmit}
              sx={{
                backgroundColor: "#1976d2", // Blue background
                color: "#ffffff", // White text
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#155a9c", // Darker blue on hover
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default SellNFT;
