import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";
import { getSIngleCLBAPI } from "../clb/getSIngleCLB";
import toast from "react-hot-toast";

const initialState = { loading: false, result: null, error: null, message: "" };

export const placeOrderAPI = createAsyncThunk(
  "place-order",
  async (data, { rejectWithValue, dispatch }) => {
   
    try {
      const response = await HttpService.post(`/orders/place-orders`, data);
      // debugger;
      console.log(response)
      // const iswebhookRes = response.data.result.webhookRes;
      const iswebhookMsg = response.data.result.webookMsg;

      if(iswebhookMsg !==""){
        toast.success(iswebhookMsg, {
          duration: 5000,
          position: "bottom-left",
        });
      }
      
      dispatch(getSIngleCLBAPI(data.clbId));
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const counterSlice = createSlice({
  name: "place-order",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(placeOrderAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(placeOrderAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(placeOrderAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const placeOrderReducer = counterSlice.reducer;

export default placeOrderReducer;
