import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { placeBidAPI } from "../../redux/bid/placeBid";
import Snack from "../../ui/Snack";
import { getClBBidDetailsAPI } from "../../redux/bid/getClBBidDetails";
import toast from "react-hot-toast";
import { dateYYMMDD } from "../../helper/date";
// import { Canvas } from "@react-three/fiber";
// import { OrbitControls, useGLTF } from "@react-three/drei";

export default function PlaceBid({ open, setOpen, CLB }) {
  const placeBidSelector = useSelector((state) => state?.placeBid);
  const { message, error } = placeBidSelector;

  const dispatch = useDispatch();

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  // const GLBViewer = ({ url }) => {
  //   const { scene } = useGLTF(url);
  //   return <primitive object={scene} />;
  // };

  const [cost, setCost] = useState({ inr: null, usd: null });

  const handleClose = () => setOpen(false);

  const handlePriceCom = async () => {
    let res = await axios.get(
      `https://openexchangerates.org/api/latest.json?app_id=3118004813b1465ebb7f3b57e1f160b6&base=USD`
    );
    const priceCompData = res?.data;
    const USD = priceCompData?.rates?.USD;
    const INR = priceCompData?.rates?.INR;
    setCost({ inr: cost?.inr, usd: cost?.inr / INR });
  };

  const handlePlaceBid = async () => {
    const currentDate = dateYYMMDD(new Date());
    const expiryDate = dateYYMMDD(CLB.endDate);

    if (!cost?.inr) {
      return setError("costUsdErr", {
        type: "custom",
        message: "Price can not be empty!",
      });
    }
    if (!cost?.usd) {
      return setError("costUsdErr", {
        type: "custom",
        message: "Price can not be empty!",
      });
    }
    await dispatch(
      placeBidAPI({
        CLBId: CLB?._id,
        price: { inr: Number(cost?.inr), usd: Number(cost?.usd) },
      })
    );
    await dispatch(getClBBidDetailsAPI(CLB?._id));

    setOpen(false);
  };

  useEffect(() => {
    handlePriceCom();
  }, [cost.inr]);
  const fileExtension = CLB?.files[0].split(".").pop().toLowerCase();
  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal">
        <Card sx={{ width: "25%" }}>
          <form onSubmit={handleSubmit(handlePlaceBid)}>
            <Stack spacing={2}>
              <Stack direction="row" sx={{ my: 2 }}>
                <Typography
                  align="center"
                  paddingLeft="26px"
                  variant="h5"
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    width: "70%",
                    marginLeft: "auto",
                  }}
                >
                  Place Your Bid
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" spacing={2}>
                {/* {(fileExtension === "glb" || fileExtension === "gltf")?   <div style={{ width: 100, height: 150 }}>
                  <Canvas>
                    <ambientLight intensity={0.5} />
                    <OrbitControls />
                    <GLBViewer url={CLB?.files[0]} />
                  </Canvas>
                </div>:  <Avatar
                  src={CLB?.files[0]}
                  variant="rounded"
                  sx={{ width: 100, height: 100 }}
                />} */}
                  <Avatar
                  src={CLB?.files[0]}
                  variant="rounded"
                  sx={{ width: 100, height: 100 }}
                />
              
               
                <Box>
                  <Typography>{CLB?.creator?.name}</Typography>
                  <Typography>{CLB?.creator?.email}</Typography>
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography>Base Price</Typography>
                  <Typography>₹ {CLB?.cost?.inr}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography>Base Price (USD)</Typography>
                  <Typography>$ {CLB?.cost?.usd}</Typography>
                </Stack>

                <TextField
                  type="number"
                  label="Price INR"
                  value={cost?.inr}
                  onChange={({ target }) =>
                    setCost({ ...cost, inr: target?.value })
                  }
                  error={errors?.costInrErr?.message}
                  helperText={errors?.costInrErr?.message}
                />
                <TextField
                  type="number"
                  sx={{ width: "100%" }}
                  label="Price USD"
                  value={cost?.usd ? parseFloat(cost?.usd).toFixed(2) : ""}
                  // error={!!errors?.costUsdErr} // Check if there's an error
                  // helperText={errors?.costUsdErr?.message}
                  // FormHelperTextProps={{
                  //   style: { color: 'red',background: "red", width:"100%"}, // Set helperText color to red when there is an error
                  // }}
                />
                {errors?.costUsdErr && (
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: "8px" }}
                  >
                    {errors.costUsdErr.message}
                  </Typography>
                )}
              </Stack>

              <Button type="submit" variant="contained" fullWidth>
                Place Bid
              </Button>
            </Stack>
          </form>
        </Card>
      </Modal>
    </>
  );
}
