import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";

const initialState = { loading: false, result: null, error: null, message: "" };

export const getEstimatedGasFeesAPI = createAsyncThunk(

  "estimate-gas-fees",
  async (payload, { rejectWithValue }) => {
   
    try {
      const response = await HttpService.get(
        `/nft/estimate-gas-fees`
      );
      console.log("response.data", response.data)
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getEstimatedGasFees = createSlice({
  name: "estimate-gas-fees",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getEstimatedGasFeesAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEstimatedGasFeesAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(getEstimatedGasFeesAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const getEstimatedGasFeesReducer = getEstimatedGasFees.reducer;

export default getEstimatedGasFeesReducer;
